<template>
  <div class="stick-post-wraper">
    <div class="forum-info">
      <div class="forum-info-container">
        <img :src="themeDetail.picUrl" alt />
        <div class="info-wraper">
          <h1 class="tag-name">
            {{themeDetail.themeName || ''}}
            <button
              @click="submitContent"
              style="cursor:pointer;border-radius:3px"
            >提交</button>
          </h1>
        </div>
      </div>
    </div>
    <div class="submit-wraper">
      <h2>添加标题</h2>
      <div class="add-title">
        <input type="text" v-model="title" placeholder="请输入内容标题" />
      </div>
      <h2>输入内容</h2>
      <div class="editor-content">
        <div id="div1" class="toolbar" ref="toolbar"></div>
        <div ref="editor" style="text-align:left;height:400px;background:#fff"></div>
      </div>
    </div>
  </div>
</template>
<script>
import E from "wangeditor";
import { saveStickInfo } from "../assets/js/api";
import OSS from "ali-oss";
const appServer =
  process.env.VUE_APP_TITLE === "build"
    ? "https://5298363890752437.cn-beijing.fc.aliyuncs.com/2016-08-15/proxy/course_release/course/oss/ossToken"
    : "https://5298363890752437.cn-beijing.fc.aliyuncs.com/2016-08-15/proxy/course_debug/course/oss/ossToken";
const bucket =
  process.env.VUE_APP_TITLE === "build"
    ? "release-bj-forum-resource"
    : "test-bj-forum-resource";
const region = "oss-cn-beijing";

export default {
  data() {
    return {
      editorContent: "",
      title: "",
      themeDetail: {}
    };
  },
  methods: {
    submitContent() {
      saveStickInfo({
        stickTitle: this.title,
        stickInfo: this.editorContent,
        themeId: this.themeDetail.themeId,
        goodsId: this.themeDetail.goodsId
      }).then(result => {
        if (result.code == 0) {
          this.$message.success(result.msg);
          this.$router.go(-1);
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    applyTokenDo(func) {
      var url = appServer;
      return OSS.urllib
        .request(url, {
          method: "GET"
        })
        .then(function(result) {
          var creds = JSON.parse(result.data);
          var client = new OSS({
            region: region,
            accessKeyId: creds.AccessKeyId,
            accessKeySecret: creds.AccessKeySecret,
            stsToken: creds.SecurityToken,
            bucket: bucket
          });

          return func(client);
        });
    },
    uploadFile(client) {
      let file = this.imageFile;
      return client.put(file.name, file).then(function(res) {
        return res;
      });
    }
  },
  mounted() {
    var _this = this;
    var editor = new E(this.$refs.toolbar, this.$refs.editor);
    this.themeDetail = JSON.parse(sessionStorage.getItem("themeDetail"));
    editor.customConfig.onchange = html => {
      this.editorContent = html;
    };
    editor.customConfig.menus = [
      "bold",
      "link", // 插入链接
      "list", // 列表
      "justify", // 对齐方式
      "quote",
      "image"
    ];
    editor.customConfig.customUploadImg = function(files, insert) {
      _this.imageFile = files[0];
      _this.applyTokenDo(_this.uploadFile).then(result => {
        insert(result.url);
      });
    };
    editor.customConfig.uploadImgParams = {
      // 如果版本 <=v3.1.0 ，属性值会自动进行 encode ，此处无需 encode
      // 如果版本 >=v3.1.1 ，属性值不会自动 encode ，如有需要自己手动 encode
      token: localStorage.getItem("token")
    };
    editor.customConfig.uploadFileName = "file";
    editor.create();
  }
};
</script>
<style lang="scss" scoped>
.stick-post-wraper {
  width: 1200px;
  margin: 0 auto;
}
.forum-info {
  margin-top: 15px;
  height: 130px;
  background: #f7f7f7;
}
.forum-info-container {
  padding: 10px 20px;
  display: flex;
  img {
    margin-right: 10px;
    width: 120px;
    height: 100px;
    background: #00d59d;
  }
  .info-wraper {
    flex: 1;

    line-height: 39px;
    color: #666666;
  }
  .tag-name {
    margin-top: 30px;
    color: #222222;
    font-size: 18px;
    button {
      background: #00d59d;
      color: #ffffff;
      width: 76px;
      height: 32px;
      text-align: center;
      float: right;
      border: none;
    }
  }
}
.submit-wraper {
  padding: 20px;
  box-sizing: border-box;
  background: #f7f7f7;
  height: calc(100vh - 220px);
  .add-title {
    background: #ffffff;
    padding: 11px;
    margin-top: 10px;
    input {
      border: none;
      width: 100%;
      display: block;
    }
  }
  h2 {
    margin: 15px 0;
    color: #333333;
    font-weight: bold;
    font-size: 18px;
  }
}
</style>